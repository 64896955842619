<template>
    <div class="guidance">
        <ul class="list flex-wrap">
            <li v-for="item in list" :key="item.id" @click="goRouter('/news/newDetail',item.id)">
                <img :src="item.thumbnail" alt="" class="banner err_image">
                <div class="info">
                    <h3 class="line_clamp1">{{item.title}}</h3>
                    <div class="text line_clamp2">
                        {{item.conAbstract}}
                    </div>
                    <div class="time">
                        发布时间：{{item.createTime}}
                    </div>
                </div>
            </li>
        </ul>
        <status
                type="result"
                v-if="isHide"
                des="暂无更多新闻"
        ></status>
        <div class="pagination flexs">
            <el-pagination
                    background
                    @current-change="handleCurrentChangeone"
                    class="work_pagination"
                    :current-page.sync="page.pageNum"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="page.pageSize"
                    layout="total,prev, pager, next"
                    :total="page.total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    import status from "@/components/public/status";
    export default {
        name: "guidance",
        components:{
            status
        },
        data(){
            return{
                list:[],
                isHide:false,
                page:{
                    pageSize:10,
                    pageNum:1,
                    total:0,
                    consultationClassName: "就业指导",
                }
            }
        },
        methods:{
            async newsApi(){
                let res = await this.$api.newsListApi(this.page);
                if (res.data.success){
                    this.list = res.data.data.records;
                    this.page.total = res.data.data.total;
                    if (this.list.length <= 0) {
                        this.isHide = true;
                    } else {
                        this.isHide = false;
                    }
                }
            },
            handleCurrentChangeone(val) {
                this.page.pageNum = val;
                this.newsApi();
            },
			goRouter(url, id) {
			  const {href} = this.$router.resolve({
			    path: url,
			    query: {
			      id: id,
			    },
			  });
			  window.open(href, "_blank");
			}
        },
        created() {
            this.newsApi()
        }
    }
</script>

<style scoped lang="less">
    .guidance{
        .list{
            background: white;
            li{
                padding: 10px;
                width:588px;
                margin-right: 20px;
                display: flex;
                cursor: pointer;
                margin-bottom: 20px;
                img{
                    min-width: 186px;
                    min-height: 138px;
                    width: 186px;
                    height: 138px;
                    border-radius: 6px;
                    margin-right: 12px;
                }
                .info{
                    position: relative;
                    margin-top: 10px;
                    padding-right:20px;
                    h3{
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    .text{
                        color: #666666;
                    }
                    .time{
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                        color: #666666;
                    }
                }
            }
            li:nth-of-type(2n){
                margin-right: 0;
            }
            li:hover{
                h3{
                    color: #00924b;
                }
            }
        }
    }
</style>