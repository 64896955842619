<template>
  <footer>
    <div class="link">
      <div class="links flex">
                <div class="a_link">
          <a
              href="javascript:;"
              target=""
          >友情链接：</a
          >
        </div>
        <div class="a_link">
          <a
              :href="n.linkUrl"
              target="_blank"
              :title="n.linkName"
              v-for="(n, i) in link"
              :key="i"
          >{{ n.linkName }}</a
          >
        </div>
      </div>
      <div class="select" style="display:flex">
        <div style="margin-right:10px" aria-label="下拉列表框">
        <el-select
            v-model="select"
            class="selects"
            placeholder="请选择"
            popper-class="select_link"
        >
          <el-option
              v-for="(n, i) in options"
              :label="n.linkName"
              :value="n.linkName"
              :key="i"
          >
            <a :href="n.linkUrl" target="_blank" :title="n.linkName">{{
                n.linkName
              }}</a>
          </el-option>
        </el-select>
        </div>
        <div style="margin-right:10px" aria-label="下拉列表框">
        <el-select
            v-model="select1"
            class="selects"
            placeholder="请选择"
            popper-class="select_link"
        >
          <el-option
              :label="n.linkName"
              :value="n.linkName"
              v-for="(n, i) in options1"
              :key="i"
          >
            <a :href="n.linkUrl" target="_blank" :title="n.linkName">{{
                n.linkName
              }}</a>
          </el-option>
        </el-select>
        </div>
        <div style="margin-right:10px" aria-label="下拉列表框">
        <el-select
            v-model="select2"
            class="selects"
            placeholder="请选择"
            popper-class="select_link"
        >
          <el-option
              :label="n.linkName"
              :value="n.linkName"
              v-for="(n, i) in options2"
              :key="i"
          >
            <a :href="n.linkUrl" target="_blank" :title="n.linkName">{{
                n.linkName
              }}</a>
          </el-option>
        </el-select>
        </div>
        <div style="margin-right:10px" aria-label="下拉列表框">
        <el-select
            v-model="select3"
            class="selects"
            placeholder="请选择"
            popper-class="select_link"
        >
          <el-option
              :label="n.linkName"
              :value="n.linkName"
              v-for="(n, i) in options3"
              :key="i"
          >
            <a :href="n.linkUrl" target="_blank" :title="n.linkName">{{
                n.linkName
              }}</a>
          </el-option>
        </el-select>
        </div>




      </div>
    </div>
    <div class="bottom">
      <div class="bottom_content flex">
        <ul class="left flex">
          <li class="li">
            <h2>企业服务</h2>
            <ul class="list">
              <li>
                <router-link to="/protocol/Introduction/" target="_blank"> 网站简介</router-link>
              </li>
              <li>
                <router-link to="/protocol/aboutUs/" target="_blank"> 联系我们</router-link>
              </li>
              <!-- <li>
                <a href="https://www.cdpee.org.cn/download/index.html" target="_blank"> 中国残联就业APP </a>
              </li> -->
            </ul>
          </li>
          <li class="li">
            <h2>用户帮助</h2>
            <ul class="list">
              <li>
                <router-link to="/protocol/law/" target="_blank">
                  法律声明
                </router-link>
              </li>
              <li>
                <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
              </li>
              <!--              <li>-->
              <!--                <router-link to="/protocol/barrierFree/" target="_blank"-->
              <!--                  >无障碍声明</router-link>-->
              <!--              </li>-->
              <li>
                <!--                <router-link to="https://wechat.cdpee.org.cn/download/index.html" target="_blank">投诉/反馈</router-link>-->
              </li>
            </ul>
          </li>
          <li class="li" style="flex:2">
            <h2>联系我们</h2>
            <ul class="list">
              <li>
                残疾人用户热线：<a href="tel:010-68060691" class="newsletter"
              >010-68060691</a
              >
              </li>
              <li>
                企业、就业服务机构热线：<a href="tel:010-68060651" class="newsletter"
              >010-68060651</a
              >
              </li>
              <li>
                企业服务邮箱：<a
                  href="mailto:cjrjcypt@sina.com"
                  class="newsletter"
              >cjrjcypt@sina.com</a
              >
              </li>
            </ul>
            <!-- <a
                href="tencent://message/?uin=192944185&Site=http://vps.shuidazhe.com&Menu=yes"
                target="_blank"
                class="qq"
                title="QQ交谈"
            >
            </a> -->
          </li>
        </ul>
        <ul class="right flex">
          <li>
            <img src="@/static/public/wechat.jpg" alt="公众号二维码"/>
            <p>公众号</p>
          </li>
          <li>
            <img src="@/static/public/apps.png" alt="APP下载二维码"/>
            <!-- <qr-code width="132" height="132"></qr-code> -->
            <p>APP下载</p>
          </li>
                    <li>
            <img src="@/static/public/qrcode2.png" alt="APP下载二维码"/>
            <!-- <qr-code width="132" height="132"></qr-code> -->
            <p>企业微信</p>
          </li>
        </ul>
      </div>
      <div class="info">
        <p>
          <span> 网站管理及技术支持：<a href="http://www.cdpf.org.cn/" target="_blank"
        >中国残疾人联合会信息中心</a
        ></span>
        <span>网站运营单位：<a href="http://www.epsoft.com.cn/" target="_blank"
        >浙江浙大网新软件产业集团有限公司
        </a></span>
         <span>网站备案：京ICP备12035391号-2</span
          ><span>版权所有：中国残疾人联合会</span>
          <!--          网站管理及技术支持：<a href="#" target="_blank">中国残联信息中心</a>-->
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      select: "国务院部门网站",
      select1: "直属单位及基金会网站",
      select2: "专门协会网站",
      select3: "省以及直辖市的就业指导中心",
      link: [],
      options: [],
      options1: [],
      options2: [],
      options3: [],
    };
  },
  created() {
    this.links();
  },
  methods: {
    async links() {
      let res = await this.$axios.get("/api/app-jycy-captcha/getAdminLink");
      if (res.data.success) {
        let data = res.data.data;
        this.options = data["01"];
        this.options1 = data["02"];
        this.options2 = data["03"];
        this.options3 = data["04"];
        this.link = data["05"];
      }
    },
  },
};
</script>

<style scoped lang="less">
footer {
height: 444px;
background: #66716C;
  .link {
    .links {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 0;
      p {
        width: 100px;
        line-height: 2em;
      }

      a {
        margin-right: 20px;
        line-height: 2em;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
color: rgba(255, 255, 255, 0.8);
line-height: 24px;
      }

      a:hover {
                      color: #fff;
                border-bottom: 1px solid white;
      }
    }

    .select {
      width: 1200px;
      margin: 0 auto;

      .selects {

        margin-right: 24px;
        width: 282px;
height: 36px;

      }

      .selects:last-child {
        margin-right: 0;
      }
    }
  }

  .bottom {
    .bottom_content {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 0 50px 0;

      .left {
        padding-top: 28px;
        flex: 6;

        .li {
          flex: 1;

          h2 {
font-size: 20px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: 28px;
          }

          .list {
            padding-top: 9px;

            li {
              padding-top: 10px;
                color:rgba(255, 255, 255, 0.8);
              a {
                width: 116px;
font-size: 14px;
color: rgba(255, 255, 255, 0.8);
line-height: 22px;
              }

              a:hover {
                color: #fff;
                border-bottom: 1px solid white;
              }
            }
          }

          .qq {
            display: block;
            margin-top: 20px;
width: 113px;
height: 36px;
background: #99A09D;
border-radius: 6px;
            background: url('~static/public/qq_connected.png');
            &:hover{
              background: url('~static/public/qq_connect.png');
            }
          }
        }
      }

      .right {
        flex: 4;
        padding-top: 28px;

        li {
          flex: 1;
          width: 132px;
          height: 132px;

          img {
            display: block;
            width: 132px;
            height: 132px;
            margin: 0 auto;
            border-radius: 8px;
            overflow: hidden;

          }

          p {
            text-align: center;
            padding-top: 10px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }

    .info {
      width: 1200px;
      padding: 12px 0;
      margin: 0 auto;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      text-align: center;
            color: #fff;

      p {
font-size: 14px;
color: #FFFFFF;
line-height: 22px;
display: flex;
    justify-content: space-between;
    &:nth-child(2n){
      margin-top: 6px;
      span{
        flex: 1;
      }
    }
        span {
          padding-right: 10px;
        }

        a {
          padding-right: 10px;
            color: #fff;

        }

        a:hover {
                          color: #fff;
                border-bottom: 1px solid white;
        }
      }
    }
  }
}
/deep/.el-input--suffix .el-input__inner{
  color: #ffffff;
background-color: #88908C;
        border-radius: 6px;
border: none;
}
/deep/.el-select .el-input .el-select__caret{
  color: #FFFFFF !important;
}
</style>
