<template>
  <el-dialog
      title="请选择职位"
      :visible.sync="show"
      width="600px"
      height="100%"
      :lock-scroll="false"
      :before-close="close"
      v-if="show"
  >
    <div class="flex">
      <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          class="marB10"
      >全选
      </el-checkbox>
      <el-button type="primary" class="all cursorP" @click.stop="joinJobFair">立即参会</el-button>
    </div>
    <div class="marB10"></div>
    <el-checkbox-group
        v-model="checkedList"
        @change="handlecheckedListChange"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
    >
      <div class="job_li" v-for="item in fairList" :key="item.id">
        <el-checkbox :label="item">
          <div class="flex top_1">
            <div class="li_one">
              <div class="flex">
                <h4 class="line_clamp1">{{ item.jobName }}</h4>
                <span>{{ item.jobSalary }}</span>
              </div>

              <ul class="one_ul flex">
                <li>
                  <span v-if="item.exp ">{{ item.exp }}</span>
                  <span v-else>无经验要求</span>
                  <el-divider direction="vertical"></el-divider>
                </li>
                <li>
                  <span v-if="item.edu">{{ item.edu }}</span>
                  <span v-else>无学历要求</span>
                  <el-divider direction="vertical"></el-divider>
                </li>
                <li v-for="(n,i) in item.distype" :key="i">
                  <span v-if="i<1">{{ n.disType }}~{{ n.disTypeLevel }}</span>
                </li>
                <li v-if="item.distype.length<=0">
                  <span>暂无残疾类别</span>
                </li>
              </ul>
              <ul class="one_ul2 line_clamp1">
                <li v-for="(n,i) in item.jobLabels" :key="i">
                  <p>{{ n.labelName }}</p>
                </li>
                <li v-if="item.jobLabels.length<=0">
                  <span>暂无福利</span>
                </li>
              </ul>
            </div>
          </div>
        </el-checkbox>
      </div>
    </el-checkbox-group>
    <status v-if="fairList==null||fairList.length<=0" type="post" des="暂无可参会职位"></status>
  </el-dialog>
</template>

<script>
export default {
  name: "companySign",
  props: {
    show: Boolean,
    id: String
  },
  data() {
    return {
      loading: false,
      fairList: [],
      checkedList: [],
      checkAll: false,
      isIndeterminate: true,
      params: {
        city: "",
        isJoin: "",
        jobFairId: "",
        pageNum: 1,
        pageSize: 10,
        jobStatus: 1,
      },
      paran: {
        jobFairId: "",
        jobId: [],
      },
    }
  },
  watch:{
    'show':function (val){
      if (val){
        this.getPostlist();
      }
    }
  },
  methods: {
    // 弹窗列表
    async getPostlist() {
      this.params.jobFairId = this.id;
      let that = this;
      this.loading = true;
      let res = await that.$axios.post(
          "/api/app-jycy-job/getJobListByComCenter",
          that.params
      );
      if (res.data.success) {
        console.log(res.data.data.records);
        this.fairList = res.data.data.records;

        this.loading = false;
      }
    },
    async joinJobFair(id, ids) {
      this.paran.jobFairId = this.id;
      let idList = [];
      this.checkedList.forEach((rs) => {
        console.log(rs.id, "dfds");
        idList.push(rs.id);
      });
      this.paran.jobId = idList.join(",");

      try {
        let res = await this.$api.joinJobFair(this.paran);
        console.log(res);
        if (res.data.success) {
          this.$message.success("参会成功");
          this.jobfairJobList();
        } else {
          this.$message.error(res.data.msg);
        }
        this.handleClose();
      } catch (error) {}
    },
    selectList() {
      this.$message.success("选中职位已成功参选！");
      this.isShow = false;
    },
    handlecheckedListChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fairList.length;
      this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.fairList.length;
    },
    close() {
      this.$emit('close', false);
      this.show = false;
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.fairList : [];
      this.isIndeterminate = false;
    },
  },
  mounted() {
    // if (this.show){
    //   this.getPostlist();
    // }
  }
}
</script>

<style scoped lang="less">
@import "~assets/css/public";

/deep/ .el-breadcrumb__item .el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

/deep/ .el-dialog {
  min-height: 700px;
  overflow: auto;

  .el-button {
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 32px;
    background: rgba(0, 146, 75, 0.2);
    color: #00924c;
    border: none;
    font-size: 14px;
  }

  .job_li {
    border: 1px solid #f1f1f1;
    border-bottom: none;
    width: 100%;
    box-sizing: border-box;
    height: 146px;
    margin-bottom: 15px;

    .top_1 {
      padding: 15px 20px 0px 40px;
      margin-bottom: 10px;
    }

    .li_one {
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 263px;

      .line_h4 {
        a {
          color: #00924c;
        }
      }

      h4 {
        font-size: 16px;
        color: #00924c;
        width: 80px;
        display: block;
      }

      span {
        color: #f65150;
        margin-left: 30px;
      }

      .one_ul {
        margin: 10px 0px;

        li {
          font-size: 12px;
          color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #666666;
            margin-left: 0px;
          }

          .el-divider {
            margin: 0px 10px;
          }
        }
      }

      .one_ul2 {
        width: 200px;

        li {
          display: inline-block;
          border: 1px solid #ebebeb;
          font-size: 12px;
          color: #666666;
          margin-right: 12px;
          padding: 0px 3px;
        }
      }
    }

    .li_two {
      align-items: center;
      justify-content: space-around;

      li {
        width: 173px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px dotted #e1e1e1;

        .nums {
          font-size: 24px;
          color: #00924c;
          margin-bottom: 10px;
        }

        .dos {
          font-size: 12px;
          color: #666666;
        }
      }

      li:last-child {
        border: none;
      }
    }

    .li_three {
      margin-left: 50px;
    }
  }

  .job_li {
    height: 130px;
    margin-bottom: 15px;
    border: 1px solid #ccc;

    .el-checkbox {
      margin-left: 30px;
    }
  }

  .top_1 {
    padding: 20px;
  }

  .all {
    color: #fff;
    margin-left: 20px;
    transform: translateY(-5px);
    background: @00;
    position: absolute;
    bottom: 40px;
    right: 50px;
  }
}

</style>